import throttle from "lodash.throttle"
import { useEffect } from "react"

const useResizeObserver = props => {
  const { ref, onResize, wait = 300 } = props

  const onResizeThrottle = throttle(onResize, wait)

  useEffect(() => {
    if (ref?.current) {
      const resizeObserver = new ResizeObserver(() => {
        onResizeThrottle && onResizeThrottle()
      })

      resizeObserver.observe(ref.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [ref])
}

export { useResizeObserver }
