import { ProgramFinder, ProgramFinder2x } from "@thumbnails/Modules"
import { getThemeOptions } from "@helpers/schemas"

export default {
  schemaType: "module",
  displayName: "Program finder",
  component: "ProgramFinder",
  category: "studies",
  dataPacks: null,
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          html: true,
          key: "subtitle",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          html: true,
          key: "detail",
          hideable: true,
          default:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.",
          humanReadable: true,
        },
        {
          title: "Images",
          type: "ComponentArray",
          maxItems: null,
          key: "images",
          whiteList: ["Image"],
          helptext: "950x1536 recomended",
        },
        {
          title: "Button",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "primaryLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "Style",
          key: "theme",
          type: "VisualUniqueSelection",
          options: getThemeOptions(["default", "accent"]),
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: "ProgramFinder",
    anchorID: null,
    theme: "default",
    title: { content: "Program Finder", tag: "h2" },
    subtitle: null,
    detail:
      "Choose your preferences and discover your next knowledge adventure",
    images: [
      {
        component: "Image",
        title: "Program finder deco image 1",
        alt: "Program finder deco image 1",
        veil: 0,
        file: {
          title: "Program finder deco image 1",
          alt: "Program finder deco image 1",
          url: "https://images.qa.thesaurus.ie.edu/program-finder-01",
        },
      },
      {
        component: "Image",
        title: "Program finder deco image 2",
        alt: "Program finder deco image 2",
        veil: 0,
        file: {
          title: "Program finder deco image 2",
          alt: "Program finder deco image 2",
          url: "https://images.qa.thesaurus.ie.edu/program-finder-02",
        },
      },
      {
        component: "Image",
        title: "Program finder deco image 3",
        alt: "Program finder deco image 3",
        veil: 0,
        file: {
          title: "Program finder deco image 3",
          alt: "Program finder deco image 3",
          url: "https://images.qa.thesaurus.ie.edu/program-finder-03",
        },
      },
      {
        component: "Image",
        title: "Program finder deco image 4",
        alt: "Program finder deco image 4",
        veil: 0,
        file: {
          title: "Program finder deco image 4",
          alt: "Program finder deco image 4",
          url: "https://images.qa.thesaurus.ie.edu/program-finder-04",
        },
      },
      {
        component: "Image",
        title: "Program finder deco image 5",
        alt: "Program finder deco image 5",
        veil: 0,
        file: {
          title: "Program finder deco image 5",
          alt: "Program finder deco image 5",
          url: "https://images.qa.thesaurus.ie.edu/program-finder-05",
        },
      },
    ],
    primaryLink: {
      component: "Link",
      text: "Find my program",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": ProgramFinder,
    "2x": ProgramFinder2x,
  },
}
