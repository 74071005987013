const ACADEMIC_AREA = {
  title: "Academic area",
  dataPacks: ["PUBLICATIONS", "TEACHERS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const AWARD_CATEGORY = {
  title: "Awards Category",
  dataPacks: ["EPICAWARDS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  editable: false,
  schema: null,
  clone: null,
  defaultValues: null,
}

const BLOG_SOURCES = {
  title: "Blogs Sources",
  dataPacks: ["BLOG"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: false,
  editable: false,
  schema: null,
  clone: null,
  defaultValues: null,
}

const BLOG_TAGS = {
  title: "Blogs Tags",
  dataPacks: ["BLOG"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: false,
  editable: false,
  schema: null,
  clone: null,
  defaultValues: null,
}

const CENTERS = {
  title: "Centers & Departments",
  dataPacks: [
    "ARTICLES",
    "AUDIOVISUAL",
    "EVENTS",
    "GENERALINFO",
    "NEWS",
    "PEOPLE",
    "PROJECTS",
    "PUBLICATIONS",
    "STORIES",
    "TESTIMONIALS",
  ],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const CLASSIFICATION_TYPE = {
  title: "Classification Type",
  dataPacks: ["EPICAWARDS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  editable: false,
  schema: null,
  clone: null,
  defaultValues: null,
}

const EVENT_TYPE = {
  title: "Event Type",
  dataPacks: ["EVENTS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const FACULTY_CATEGORY = {
  title: "Faculty category",
  dataPacks: ["TEACHERS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const FORMAT = {
  title: "Format",
  dataPacks: ["EVENTS", "STORIES", "STUDIES", "TESTIMONIALS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const GENDER = {
  title: "Gender",
  dataPacks: ["PEOPLE", "STORIES", "TEACHERS", "TESTIMONIALS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const LANGUAGE = {
  title: "Language",
  dataPacks: ["STUDIES"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const MODE_STUDY = {
  title: "Mode of Study",
  dataPacks: ["STUDIES"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const PODCAST_TYPE = {
  title: "Podcast type",
  dataPacks: ["AUDIOVISUAL"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const PROFILES = {
  title: "Profiles",
  dataPacks: ["STORIES"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const PROGRAM_TYPE = {
  title: "Program type",
  dataPacks: ["STUDIES"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const PUBLICATION_TYPE = {
  title: "Publication type",
  dataPacks: ["PUBLICATIONS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const REGION = {
  title: "Region",
  dataPacks: [
    "EVENTS",
    "GENERALINFO",
    "OFFICES",
    "PEOPLE",
    "PROJECTS",
    "STORIES",
    "TEACHERS",
    "TESTIMONIALS",
  ],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const SCHOLARSHIP_TYPE = {
  title: "Scholarship type",
  dataPacks: ["SCHOLARSHIP"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const SECTOR = {
  title: "Sector",
  dataPacks: ["PROJECTS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const STAGE_TARGET = {
  title: "Stage Target",
  dataPacks: ["EVENTS", "NEWS", "STORIES", "TESTIMONIALS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const STUDY_TYPE = {
  title: "Study type",
  dataPacks: ["STORIES"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const SUBJECT_AREA = {
  title: "Subject Area & Values",
  dataPacks: [
    "ARTICLES",
    "AUDIOVISUAL",
    "EVENTS",
    "GENERALINFO",
    "INSIGHTS",
    "NEWS",
    "PEOPLE",
    "PROJECTS",
    "PUBLICATIONS",
    "STORIES",
    "TEACHERS",
    "TESTIMONIALS",
  ],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const TOPIC = {
  title: "Topic",
  dataPacks: [
    "ARTICLES",
    "AUDIOVISUAL",
    "EVENTS",
    "GENERALINFO",
    "INSIGHTS",
    "NEWS",
    "PEOPLE",
    "PROJECTS",
    "PUBLICATIONS",
    "STORIES",
    "TEACHERS",
    "TESTIMONIALS",
  ],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const WEIGHT_CATEGORY = {
  title: "Weight Category",
  dataPacks: ["STUDIES"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

const YEAR = {
  title: "Year",
  dataPacks: ["EPICAWARDS"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  editable: false,
  schema: null,
  clone: null,
  defaultValues: null,
}

const YEARS_EXPERIENCE = {
  title: "Years of experience",
  dataPacks: ["STUDIES"],
  local: false,
  taxonomy: true,
  fromPage: false,
  translate: true,
  schema: null,
  clone: null,
  defaults: null,
}

// const RANKING_ENTITY = {
//   title: "Ranking Entity",
//   dataPacks: ["RANKINGS"],
//   local: false,
//   taxonomy: true,
//   fromPage: false,
//   translate: true,
//   schema: null,
//   clone: null,
//   defaults: null,
// }

export {
  ACADEMIC_AREA,
  AWARD_CATEGORY,
  BLOG_SOURCES,
  BLOG_TAGS,
  CENTERS,
  CLASSIFICATION_TYPE,
  EVENT_TYPE,
  FACULTY_CATEGORY,
  FORMAT,
  GENDER,
  LANGUAGE,
  MODE_STUDY,
  PODCAST_TYPE,
  PROFILES,
  PROGRAM_TYPE,
  PUBLICATION_TYPE,
  REGION,
  SCHOLARSHIP_TYPE,
  SECTOR,
  STAGE_TARGET,
  STUDY_TYPE,
  SUBJECT_AREA,
  TOPIC,
  WEIGHT_CATEGORY,
  YEAR,
  YEARS_EXPERIENCE,
}
