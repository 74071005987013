import {
  ACADEMIC_AREA,
  AWARD_CATEGORY,
  BLOG_SOURCES,
  BLOG_TAGS,
  CENTERS,
  CLASSIFICATION_TYPE,
  EVENT_TYPE,
  FACULTY_CATEGORY,
  FORMAT,
  GENDER,
  LANGUAGE,
  MODE_STUDY,
  PODCAST_TYPE,
  PROFILES,
  PROGRAM_TYPE,
  PUBLICATION_TYPE,
  REGION,
  SCHOLARSHIP_TYPE,
  SECTOR,
  STAGE_TARGET,
  STUDY_TYPE,
  SUBJECT_AREA,
  TOPIC,
  WEIGHT_CATEGORY,
  YEAR,
  YEARS_EXPERIENCE,
} from "./categories"

export default {
  ACADEMIC_AREA,
  AWARD_CATEGORY,
  BLOG_SOURCES,
  BLOG_TAGS,
  CENTERS,
  CLASSIFICATION_TYPE,
  EVENT_TYPE,
  FACULTY_CATEGORY,
  FORMAT,
  GENDER,
  LANGUAGE,
  MODE_STUDY,
  PODCAST_TYPE,
  PROFILES,
  PROGRAM_TYPE,
  PUBLICATION_TYPE,
  REGION,
  SCHOLARSHIP_TYPE,
  SECTOR,
  STAGE_TARGET,
  STUDY_TYPE,
  SUBJECT_AREA,
  TOPIC,
  WEIGHT_CATEGORY,
  YEAR,
  YEARS_EXPERIENCE,
}
