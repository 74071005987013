import allThemes from "@themes/config.json"
import { themes, linkStyle, backgroundThemes } from "@theme-img"

export const getThemeOptions = (subthemes = ["defaultAlt", "default"]) => {
  return allThemes.map(theme => ({
    theme: theme.id,
    options: subthemes.map(subtheme => ({
      value: subtheme,
      img: themes[theme.id] && themes[theme.id][subtheme],
    })),
  }))
}

export const getBackgroundThemeOptions = (
  subthemes = ["defaultAlt", "default"]
) => {
  return allThemes.map(theme => ({
    theme: theme.id,
    options: subthemes.map(subtheme => ({
      value: subtheme,
      img: backgroundThemes[theme.id] && backgroundThemes[theme.id][subtheme],
    })),
  }))
}

export const getLinkStyles = (
  linkStyles = ["primary", "secondary", "link"]
) => {
  return allThemes.map(theme => ({
    theme: theme.id,
    options: linkStyles.map(style => ({
      value: style,
      img: linkStyle[theme.id] && `${linkStyle[theme.id][style]}`,
    })),
  }))
}

export const allModulesWhitelist = [
  "Accordion",
  "Accordion50",
  "AddressCollection",
  "BasicContent",
  "BasicWysiwyg",
  "CardCollection",
  "CTACollection",
  "ChartCollection",
  "Countdown",
  "CypherCollection",
  "ProgramsPresentationAuto",
  "DocumentDownload",
  "DownloadCollection",
  "DownloadForm",
  "FeaturedAudiovisual",
  "FeaturedBlock",
  "FeaturedBluePanel",
  "FeaturedProgram",
  "FeaturedTextBluePanels",
  "FeedbackForm",
  "FloatingCTA",
  "HorizontalTabs",
  "Iframe",
  "IframeQuiz",
  "ImageGallery",
  "InformationStructure",
  "InformativeHeader",
  "Intro",
  "IntroForm",
  "IntroTextCard",
  "ListCollapsed",
  "LoadScript",
  "LocationMap",
  "LogoCollection",
  "MasonryCollection",
  "Milestones",
  "PopUp",
  "Principles",
  "ProfileCollection",
  "ProgramFinder",
  "ProgramsPresentation",
  "RegistrationWall",
  "RequestInfo",
  "RequestInfoCollection",
  "RequestInfoWithModal",
  "ScrollIndicator",
  "SearchModule",
  "SimpleFloatingCTA",
  "Slider",
  "SocialMedia",
  "SocialShare",
  "Spacer",
  "StepsCollection",
  "StudentPath",
  "StudyPlan",
  "Table",
  "TagDistributor",
  "TypographicVideo",
  "Timeline",
  "VerticalSlider",
  "VerticalTabs",
  "VideoCollection",
  "VideoGallery",
  "VideoPlaylist",
  "WebinarCollection",
  "Wysiwyg",
]

export const distributorModulesWhitelist = [
  "ArticlesDistributor",
  "AudiovisualDistributor",
  "BlogDistributor",
  "EpicAwardsDistributor",
  "EventsDistributor",
  "GeneralInfoDistributor",
  "InsightsDistributor",
  "NewsDistributor",
  "PersonDistributor",
  "ProgramDistributor",
  "PublicationDistributor",
  "ScholarshipsDistributor",
  "StoriesDistributor",
  "ProjectDistributor",
]

export const allSectionList = {
  AcademicVideo: ["relatedContent"],
  ArticlesList: ["relatedContent"],
  AudiovisualList: ["relatedContent"],
  BasicTemplate: ["mainContent"],
  EpicAwardDetail: ["mainContent", "relatedContent"],
  EventDetail: ["mainContent", "relatedContent"],
  EventList: ["relatedContent"],
  GeneralInfoDetail: ["gidMainContent", "gidRelatedContent"],
  GeneralInfoList: ["relatedContent"],
  Landing: ["mainContent"],
  NewsDetail: ["relatedContent"],
  NewsList: ["relatedContent"],
  ProgramDetail: ["relatedContent"],
  ProgramsList: ["relatedContent"],
  ProjectDetail: ["mainContent", "relatedContent"],
  ProjectList: ["relatedContent"],
  PublicationDetail: ["relatedContent"],
  PublicationsList: ["relatedContent"],
  ScholarshipsList: ["relatedContent"],
  SearchResults: ["mainContent", "relatedContent"],
  SitemapTemplate: ["relatedContent"],
  StoriesList: ["relatedContent"],
  StoryDetail: ["mainContent", "relatedContent"],
  TeacherDetail: ["mainContent", "relatedContent"],
  TeachersList: ["relatedContent"],
}
